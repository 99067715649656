/*
 * @Author: laishaojiang
 * @Date: 2021-09-13 19:41:59
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-22 14:33:32
 * @
# Description:
 */
export default {
  data() {
    return {
      isShowSendPhone: false,
      isShowRecPhone: false
    }
  },

  computed: {
    eyesSrc() {
      return (val) =>  val ? 'https://cdn.kuaidi100.com/images/rebuild/icon-open_lock.png' : 'https://cdn.kuaidi100.com/images/rebuild/icon-close_lock.png'
    }
  },
  methods: {
    togglePhone(val) {
      this[val] = !this[val]
    }
  }
}
